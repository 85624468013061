import {
  Component, Input, Output, OnChanges, EventEmitter, Renderer,
  ViewEncapsulation, ContentChild, TemplateRef, HostListener, ViewChild
} from '@angular/core';
import { TreeModel } from '../models/tree.model';
import { TreeNode } from '../models/tree-node.model';
import { TreeDraggedElement } from '../models/tree-dragged-element.model';
import { TreeOptions } from '../models/tree-options.model';
import { TreeViewportComponent } from './tree-viewport.component';

import * as _ from 'lodash';

const { includes, pick }  = _;


export class TreeComponent implements OnChanges {
  _nodes: any[];
  _options: TreeOptions;

   loadingTemplate: TemplateRef<any>;
   treeNodeTemplate: TemplateRef<any>;
   treeNodeWrapperTemplate: TemplateRef<any>;
   treeNodeFullTemplate: TemplateRef<any>;

  // Will be handled in ngOnChanges
   set nodes(nodes: any[]) { };
   set options(options: TreeOptions) { };

   set focused(value: boolean) {
    this.treeModel.setFocus(value);
  }

   set state(state) {
    this.treeModel.setState(state);
  }

   toggleExpanded;
   activate;
   deactivate;
   focus;
   blur;
   updateData;
   initialized;
   moveNode;
   copyNode;
   loadNodeChildren;
   changeFilter;
   event;
   stateChange;

  constructor(
    public treeModel: TreeModel,
    public treeDraggedElement: TreeDraggedElement,
    private renderer: Renderer) {

      treeModel.eventNames.forEach((name) => this[name] = new EventEmitter());
      treeModel.subscribeToState((state) => this.stateChange.emit(state));
  }

  
  onKeydown($event) {
    if (!this.treeModel.isFocused) return;
    if (includes(['input', 'textarea'],
        document.activeElement.tagName.toLowerCase())) return;

    const focusedNode = this.treeModel.getFocusedNode();

    this.treeModel.performKeyAction(focusedNode, $event);
  }

  
  onMousedown($event) {
    const insideClick = this.renderer.invokeElementMethod($event.target, 'closest', ['Tree']);

    if (!insideClick) {
      this.treeModel.setFocus(false);
    }
  }

  ngOnChanges(changes) {
    this.treeModel.setData({
      options: changes.options && changes.options.currentValue,
      nodes: changes.nodes && changes.nodes.currentValue,
      events: pick(this, this.treeModel.eventNames)
    });
  }
static decorators: DecoratorInvocation[] = [
{ type: Component, args: [{
  selector: 'Tree, tree-root',
  providers: [TreeModel],
  styles: [],
  template: `
    <tree-viewport>
      <div
        class="angular-tree-component"
        [class.node-dragging]="treeDraggedElement.isDragging()"
        [class.angular-tree-component-rtl]="treeModel.options.rtl">
        <tree-node-collection
          *ngIf="treeModel.roots"
          [nodes]="treeModel.roots"
          [treeModel]="treeModel"
          [templates]="{
            loadingTemplate: loadingTemplate,
            treeNodeTemplate: treeNodeTemplate,
            treeNodeWrapperTemplate: treeNodeWrapperTemplate,
            treeNodeFullTemplate: treeNodeFullTemplate
          }">
        </tree-node-collection>
        <tree-node-drop-slot
          class="empty-tree-drop-slot"
          *ngIf="treeModel.isEmptyTree()"
          [dropIndex]="0"
          [node]="treeModel.virtualRoot">
        </tree-node-drop-slot>
      </div>
    </tree-viewport>
  `
}, ] },
];
/** @nocollapse */
static ctorParameters: () => ({type: any, decorators?: DecoratorInvocation[]}|null)[] = () => [
{type: TreeModel, },
{type: TreeDraggedElement, },
{type: Renderer, },
];
static propDecorators: {[key: string]: DecoratorInvocation[]} = {
'loadingTemplate': [{ type: ContentChild, args: ['loadingTemplate', ] },],
'treeNodeTemplate': [{ type: ContentChild, args: ['treeNodeTemplate', ] },],
'treeNodeWrapperTemplate': [{ type: ContentChild, args: ['treeNodeWrapperTemplate', ] },],
'treeNodeFullTemplate': [{ type: ContentChild, args: ['treeNodeFullTemplate', ] },],
'nodes': [{ type: Input },],
'options': [{ type: Input },],
'focused': [{ type: Input },],
'state': [{ type: Input },],
'toggleExpanded': [{ type: Output },],
'activate': [{ type: Output },],
'deactivate': [{ type: Output },],
'focus': [{ type: Output },],
'blur': [{ type: Output },],
'updateData': [{ type: Output },],
'initialized': [{ type: Output },],
'moveNode': [{ type: Output },],
'copyNode': [{ type: Output },],
'loadNodeChildren': [{ type: Output },],
'changeFilter': [{ type: Output },],
'event': [{ type: Output },],
'stateChange': [{ type: Output },],
'onKeydown': [{ type: HostListener, args: ['body: keydown', ['$event'], ] },],
'onMousedown': [{ type: HostListener, args: ['body: mousedown', ['$event'], ] },],
};
}

interface DecoratorInvocation {
  type: Function;
  args?: any[];
}
